<template>
  <div class="sb-offers-selection-grid inner-container" :style="`--selectionGridBg: ${bg_color}`">
    <SbOffersSection v-for="offer in offers" :key="offer.uid" :offers="[offer]" :compact="true">
      <MainButton
        :label="$t('actions.knowMore')"
        :link="getStoryByUid(offer) ? `/offers/${getStoryByUid(offer).slug}` : '/'"
        class-variant="btn-blue btn-hover-primary"
      />
    </SbOffersSection>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SbOffersSection from '@/components/Storyblok/SbOffersSection'
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'SbOffersSelectionGrid',
  components: {
    SbOffersSection,
    MainButton,
  },
  props: {
    offers: { type: Array, default: () => [] },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: undefined },
  },
  computed: {
    ...mapGetters('proArea', ['getStoryByUid']),
  },
}
</script>

<style lang="scss">
.sb-offers-selection-grid {
  background-color: var(--selectionGridBg);
  display: grid;
  gap: $spacing-md;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 1000px;
  padding: $spacing-lg;
  @include mq($mq-sm) {
    padding: $spacing-sm;
  }
  @include mq($mq-xs) {
    display: block;
  }
  .sb-offer-section {
    background-color: var(--selectionGridBg);
    padding: $spacing-lg $spacing-md;

    @include mq($mq-sm) {
      padding: $spacing-md 0;
    }
    .inner-container {
      padding: 0;
    }
    &-container-wrapper {
      @include mq($mq-md) {
        flex-direction: column;
      }
      @include mq($mq-xs) {
        flex-direction: row;
      }
      &-left-img {
        width: 150px;
        height: 150px;
        @include mq($mq-md) {
          width: 100%;
          height: auto;
        }
        @include mq($mq-xs) {
          width: 150px;
          height: 150px;
        }
      }
      &-right-title {
        font-size: pxToRem(20px);
      }
    }
  }
}
</style>
